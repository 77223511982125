import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import withStyle from './styles';
import handleDirectDownload from './components/handleDirectView';
import SummaryData from './components/SummaryData';
import CurrentView from './components/CurrentViews';
import DataQualitySliderNoProcessing from '../../../Components/DataQualitySliderNoProcessing';
import { HOST, BACKEND_PORT } from '../../../Utils/Networker';

function downloadReport(productId, since, until) {
    const tz = (new Date()).getTimezoneOffset();
    const url = `${HOST}:${BACKEND_PORT}/measurements/download3?productId=${productId}&since=${since}&until=${until}&timezoneOffset=${tz}`;

    window.location.assign(url);
    window.alert('Your download will start when the report has been generated.');
};

export default function ProductDataHeaderNoProcessing({
  product, isSuperUser, summary, N, since, until, computedStatsData, surveysCompleted, amountChange, duration, frequency, perLabel, volumeUse,
  labelsHalfRefill, labelsAtRefill, dateRangeDisplayed, amountLeft
}) {
  const [loading, setLoading] = useState(false);
  const [dataCleaned, setDataCleaned] = useState(false);

  useEffect(() => {
    if (!product) {
      setLoading(true);
    } else if (product && summary && computedStatsData) {
      setLoading(false);
    }
  }, [product, summary, computedStatsData, amountChange]);

  useEffect(() => {
    setDataCleaned(false);
    if (product && (product.tier.includes('ANALYTICS'))) {
      setDataCleaned(product.dataCleaned);
    }

  }, [product]);

  const classes = withStyle();

  return <Card className={classes.productDataHeader} elevation={3}>
    <CardHeader
      className={classes.productDataHeaderTitle}
      avatar={
        product ? <Avatar alt={product.name} src={product.iconURL}
          className={classes.bigAvatar}>{product.name.substr(0, 1)}
        </Avatar> : <Typography>Loading</Typography>
      }
      title={
        product ? <Typography variant="h3">
          {product.name}
          <Typography variant="caption" paragraph>
            {product.productId}
          </Typography>
        </Typography> :
          <Typography variant="h4">Loading</Typography>
      }
      action={
        <React.Fragment>
          <DataQualitySliderNoProcessing
            platinum={dataCleaned}
            summary={summary}
            N={N} />
          {/* {product ? <Button
              variant="outlined"
              color="secondary"
              onClick={async () => {
                if (window.confirm('Try our experimental AI-enabled data cleaner? (This will take some time)')) {
                  setLoading(true);
                  setCleaning(true);
                  await Networker.post({
                    root: 'products',
                    inner: `clean/${product._id}`,
                    body: {
                      cleaners: ['transit']
                    }
                  });
                  setLoading(false);
                  window.alert('Data has been automatically processed');
                  window.location.reload();
                }
              }} id={product._id} disabled={loading} >
                {cleaning ? 'Cleaning in progress' :
                  <Typography variant="body1" style={{textTransform: 'capitalize'}}>
                    Adrich
                    <Typography variant="overline"  color="error">
                      <small><strong>.ai</strong></small>
                    </Typography>
                </Typography>}
            </Button> : null} */}
        </React.Fragment>
      }
      subheader={
        <SummaryData product={product} summary={summary}
          hasSurvey={surveysCompleted} isSuperUser={isSuperUser} />
      }>
    </CardHeader>
    <CardContent>
      <CurrentView N={N} dataSummary={computedStatsData || []} amountChange={amountChange} duration={duration}
        frequency={frequency}
        perLabel={perLabel}
        volumeUse={volumeUse}
        labelsHalfRefill={labelsHalfRefill}
        labelsAtRefill={labelsAtRefill}
        dateRangeDisplayed={dateRangeDisplayed}
        amountLeft={amountLeft}
      />
    </CardContent>
    <CardActions>
      {product && (isSuperUser || (summary && summary.numMeasurements > 0)) ?
        <div>
        <Button className={classes.iconFill}
          onClick={() => handleDirectDownload(product._id, since, until, N)} id={product._id} disabled={loading}>
          Download Current View .CSV&nbsp;&nbsp;
          <DownloadIcon fontSize="large" id={product.name} />
          &nbsp;&nbsp;
        </Button>
        <Button className={classes.iconFill} onClick={() => downloadReport(product._id, since, until, N)} disabled={loading}>
          Download Full Report
          <DownloadIcon fontSize="large" id={product.name} />
          &nbsp;&nbsp;
        </Button>
        </div> : <Typography variant="h6">
          More measurements must be collected before CSV downloads are enabled
        </Typography>}
    </CardActions>
  </Card>;
}
